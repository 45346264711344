<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="60%"
    >
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-col :md="8" :sm="8">
            <el-form-item label="项目代码" prop="number">
              <el-input
                placeholder="请输入项目代码"
                v-model="addEditForm.number"
                :disabled="editShow"
                prop="number"
              />
            </el-form-item>
          </el-col>
          <el-col :md="8" :sm="8">
            <el-form-item label="名称" prop="name">
              <el-input
                placeholder="请输入名称"
                v-model="addEditForm.name"
                :disabled="this.types == 3"
                prop="name"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>

          <el-col :md="8" :sm="8">
            <el-form-item label="项目类型" prop="type">
              <el-select
                v-model="addEditForm.type"
                filterable
                placeholder="请选择"
                :disabled="this.types == 3"
              >
                <el-option
                  v-for="item in projectTypeList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <!-- <el-col :md="8" :sm="8">
            <el-form-item label="项目归属" prop="belongCompany">
              <el-input
                placeholder="请输入项目归属"
                v-model="addEditForm.belongCompany"
                prop="belongCompany"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col> -->
          <el-col :md="8" :sm="8">
            <el-form-item label="项目负责人" prop="head">
              <el-select
                v-model="addEditForm.head"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading"
                :disabled="this.types == 3"
              >
                <el-option
                  v-for="item in userGetList"
                  :key="item.userSign"
                  :label="item.userName"
                  :value="item.userSign"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :sm="8">
            <el-form-item label="项目审核人" prop="reviewedBy">
              <el-select
                v-model="addEditForm.reviewedBy"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading"
                :disabled="this.types == 3"
              >
                <el-option
                  v-for="item in userGetList"
                  :key="item.userSign"
                  :label="item.userName"
                  :value="item.userSign"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="8" :sm="8">
            <el-form-item label="项目分类" prop="classify">
              <el-select
                v-model="addEditForm.classify"
                filterable
                placeholder="请选择"
                :disabled="this.types == 3"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="12" :sm="8">
            <el-form-item label="日期" prop="timeSolt">
              <el-date-picker
                v-model="addEditForm.timeSolt"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :disabled="this.types == 3"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="项目所在地" prop="location">
              <el-input
                placeholder="请输入项目所在地"
                v-model="addEditForm.location"
                prop="location"
                clearable
                @clear="handleQuery()"
                :disabled="this.types == 3"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="项目描述" prop="describes">
              <el-input
                placeholder="请输入项目描述"
                v-model="addEditForm.describes"
                prop="describe"
                clearable
                @clear="handleQuery()"
                :disabled="this.types == 3"
              />
            </el-form-item> </el-col
        ></el-row>

        <el-row :gutter="24">
          <el-col :md="12" :sm="24">
            <el-form-item label="报销:公司名称" prop="invoiceName">
              <el-input
                v-model="addEditForm.invoiceName"
                :disabled="this.types == 3"
              />
            </el-form-item>
          </el-col>

          <el-col :md="12" :sm="24">
            <el-form-item label="报销:公司税号" prop="invoiceDuty">
              <el-input
                v-model="addEditForm.invoiceDuty"
                :disabled="this.types == 3"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="12" :sm="24">
            <el-form-item label="地址及电话" prop="invoiceAddress">
              <el-input
                v-model="addEditForm.invoiceAddress"
                :disabled="this.types == 3"
              />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="开户行及账号" prop="invoiceNumber">
              <el-input
                v-model="addEditForm.invoiceNumber"
                :disabled="this.types == 3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        class="el-icon-plus"
        round
        style="margin: 0px 10px"
        @click="trafficAddButton"
        :disabled="this.types == 3"
      />
      <el-table :data="projectMember">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column
          label="操作"
          prop="trafficMoney"
          width="70"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-minus"
              round
              :disabled="scope.row.types == 3"
              @click.native.prevent="
                deleteRow(scope.$index, projectMember, scope.row)
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          label="成员"
          prop="memberSign"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.memberSign"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
              :disabled="scope.row.types == 3"
            >
              <el-option
                v-for="item in userGetList"
                :key="item.userSign"
                :label="item.userName"
                :value="item.userSign"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          label="项目角色"
          prop="projectRole"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.projectRole"
              filterable
              placeholder="请选择"
              :disabled="scope.row.types == 3"
            >
              <el-option
                v-for="item in projectRoleList"
                :key="item.key"
                :label="item.label"
                :value="item.value + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          label="雇佣关系"
          prop="hire"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.hire"
              filterable
              placeholder="请选择"
              :disabled="scope.row.types == 3"
            >
              <el-option
                v-for="item in hireList"
                :key="item.key"
                :label="item.label"
                :value="item.value + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="邮箱" prop="email" align="center" width="200">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.email"
              :disabled="scope.row.types == 3"
            />
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone" align="center" width="200">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.phone"
              :disabled="scope.row.types == 3"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="身份证"
          prop="identityCard"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.identityCard"
              :disabled="scope.row.types == 3"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="时间"
          prop="timeSolt"
          align="center"
          width="400"
        >
          <template slot-scope="scope">
            <el-date-picker
              :disabled="scope.row.types == 3"
              v-model="scope.row.timeSolt"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>

        <el-table-column
          label="项目权限"
          prop="purview"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-select
              :disabled="scope.row.types == 3"
              v-model="scope.row.purview"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in purviewList"
                :key="item.key"
                :label="item.label"
                :value="item.value + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          label="描述"
          prop="describes"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.describes"
              :disabled="scope.row.types == 3"
            />
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer"  v-if="this.types != 3">
        <el-button @click="cancelForm">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button type="primary" @click="addFormButton()" :loading="loading">{{
          loading ? "提交中 ..." : "确 定"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { userListApi } from "@/api/system/user/user.js";
import {
  selectprojectByIdApi,
  saveOrUpdateApi,
} from "@/api/work/project/project";
export default {
  data() {
    return {
      sizeForm: {},
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      projectMember: [],
      addEditForm: {},
      userList: [],
      userGetList: [],
      timer: null,
      list: [],
      ids: null,
      projectTypeList: [
        {
          key: 1,
          value: 1,
          label: "普通项目",
        },
      ],
      classifyList: [
        {
          key: 2,
          value: 2,
          label: "二类",
        },
      ],
      purviewList: [
        {
          key: 1,
          value: 1,
          label: "操作",
        },
      ],
      projectRoleList: [
        {
          key: 1,
          value: 1,
          label: "项目经理",
        },
        {
          key: 2,
          value: 2,
          label: "前端开发",
        },
        {
          key: 3,
          value: 3,
          label: "后端开发",
        },
        {
          key: 4,
          value: 4,
          label: "测试",
        },
        {
          key: 5,
          value: 5,
          label: "产品",
        },
      ],
      hireList: [
        {
          key: 1,
          value: 1,
          label: "正岗",
        },
        {
          key: 2,
          value: 2,
          label: "正岗-外包",
        },
        {
          key: 3,
          value: 3,
          label: "项目-外包",
        },
      ],
      types: 1,
    };
  },
  mounted() {
    // this.list = this.userList.map((item) => {
    //   return { value: `value:${item}`, label: `label:${item}` };
    // });
    console.log("----");
  },
  methods: {
    show(id, type) {
      console.log("----", type);
      this.types = type;
      this.initProject();
      this.dialog = true;
      if (type == 2) {
        this.projectMemberReqVOList = [];
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 3) {
        this.projectMemberReqVOList = [];
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "查看";
      }
      if (type == 1) {
        this.title = "新增";
      }
    },
    initProject() {
      userListApi().then((res) => {
        this.userList = res.result;
      });
    },

    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.projectMemberReqVOList = [];
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      this.addEditForm.startTime = this.addEditForm.timeSolt[0];
      this.addEditForm.endTime = this.addEditForm.timeSolt[1];
      this.addEditForm.projectMemberReqVOList = this.projectMember;
      console.log(this.addEditForm);
      saveOrUpdateApi(this.addEditForm).then((res) => {
        this.cancelForm();
      });
    },
    //查询详情
    selectByIdButton(code) {
      console.log(code);
      selectprojectByIdApi({ code: code, types: this.types }).then((res) => {
        this.addEditForm = res.result;
        if (null != res.result.projectInvoiceHeaderVO) {
          this.addEditForm.invoiceName =
            res.result.projectInvoiceHeaderVO.invoiceName;
          this.addEditForm.invoiceDuty =
            res.result.projectInvoiceHeaderVO.invoiceDuty;
          this.addEditForm.invoiceAddress =
            res.result.projectInvoiceHeaderVO.invoiceAddress;
          this.addEditForm.invoiceNumber =
            res.result.projectInvoiceHeaderVO.invoiceNumber;
        }

        this.userGetList = this.userList.filter((item1) =>
          res.result.userSignList.some((item2) => item1.userSign === item2)
        );
        this.projectMember = res.result.projectMemberReqVOList;
        this.$set(this.addEditForm, "timeSolt", [
          res.result.startTime,
          res.result.endTime,
        ]);
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    trafficAddButton() {
      var data = {};
      this.projectMember.push(data);
    },
    remoteMethod(query) {
      console.log("1", query);
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.userGetList = this.userList.filter((item) => {
            return (
              item.userName.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          });
        }, 200);
      } else {
        this.userGetList = [];
      }
    },
    deleteRow(index, dataList, row) {
      dataList.splice(index, 1);
    },
  },
};
</script>
<style scoped>
</style>



