<template>
  <div class="app-container">
    <div v-show="dataViewShow">
      <!-- 查询区域 -->
      <div>
        <el-form layout="inline">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8">
              <el-button @click="selectDataListButton()">查询</el-button>
            </el-col>
            <el-col :md="6" :sm="8"> </el-col>
          </el-row>
        </el-form>
      </div>
      <!--     列表区域   -->
      <el-table :data="dataColumnList">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="selectButton(scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectDayAndMoneyViewButton(scope.row)"
              >查看视图</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="selectWorkViewButton(scope.row)"
              >查看工作视图</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="项目编码" align="center" prop="code" />
        <el-table-column label="项目名称" align="center" prop="name" />
        <el-table-column label="开始时间" align="center" prop="startTime" />
        <el-table-column label="结束时间" align="center" prop="endTime" />
      </el-table>

      <pagination
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </div>
    <addEdit ref="addEditFrom" />
    <dataWorkViewsInfo ref="dataWorkViewsInfoForm" v-show="!dataViewShow&&!dataViewsWorkInfoShow"/>
  </div>
</template>


<script>
import addEdit from "../info/modules/addEditproject.vue";
import { listprojectApi } from "@/api/work/project/project";
import dataWorkViewsInfo from "../dataViews/workView.vue";
export default {
  components: {
    dataWorkViewsInfo,
    addEdit
  },
  data() {
    return {
      // 选中数组
      questionnaireId: "",
      radio: null,
      dataViewShow:true,
      dataViewsInfoShow: true,
      dataViewsWorkInfoShow:true,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    selectDataListButton() {
      listprojectApi().then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    selectButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.addEditFrom.show(row.code, 3);
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    selectWorkViewButton(row) {
      this.dataViewShow=false
      this.dataViewsWorkInfoShow = !this.dataViewsWorkInfoShow;
      this.$refs.dataWorkViewsInfoForm.show(row,1);
    },
    selectDayAndMoneyViewButton(row) {
      this.dataViewShow=false
      this.dataViewsWorkInfoShow = !this.dataViewsWorkInfoShow;
      this.$refs.dataWorkViewsInfoForm.show(row,2);
    },
  },
};
</script>



